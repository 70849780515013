import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  title = 'svpp-frontend';

  ngOnInit(): void {
    this.addMetaTags();
  }

  addMetaTags() {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = environment.enableCrawlers
      ? 'index, follow'
      : 'noindex, nofollow';
    document.getElementsByTagName('head')[0].appendChild(metaTag);
  }
}
